@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    @include tablet {
        padding: 1rem 2rem;
    }

    @include desktop {
        padding: 1.25rem 10rem;
    }

    &__top {

        &-logo {
            width: 10rem;
        }

    }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 0;

        & a {

            &:link,
            &:visited {
                text-decoration: none;
                color: black;
            }

            &:hover {
                color: blue;
            }
        }
    }
}

.active {
    margin: 0;
    color: orange !important;
}
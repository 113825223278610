@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home {
    padding: 0rem;
    // text-align: center;
    margin: 5rem 1rem;

    &__logo {
        width: 30rem;
        padding: 1rem;

        @include tablet {
            width: 40rem
        }
    }
}